import Preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';
import icons from './icons';

export default {
  ...Preset,
  colors: {
    bgcolor: '#F2F4F7',
    logoutBg: '#f3f4f7',
    ...Preset.colors,
    primary: {
      main: '#3860AA',
      dark: '#029EFF',
      light: '#EFF7FF',
    },
    secondary: {
      main: '#0198FE',
      dark: '#515151',
      light: '#616161',
    },
    lightText: '#707070',
    borderLight: '#BFC5D1',
    placeholder: '#ABABAB',
    text: '#333333',
    gray: {
      ...Preset.colors.gray,
      1000: '#333333',
      2000: '#222222',
      6000: '#666666',
    },
    task: {
      avatar: '#1B6AAE',
      avatarBg: '#0079fe0d',
      boardBg: '#bfc5d147',
      notStarted: '#DC554F',
      completed: '#81CF2B',
      inProgress: '#EDA84C',
      ownBg: 'rgba(0,121,254,0.06)',
    },
    message: {
      border: '#70707033',
      selectedBg: '#0198fe12',
    },
    reports: {
      type: '#919191',
    },
    shadow: '#00000010',
    sidebar: {
      border: '#0498fe',
      profile: '#0079AB',
      logout: '#FF606E',
      bg: 'rgba(1, 152, 254, 0.07)',
    },
    loginButton: '#8A8F98',
    customButton: '#3860AA',
    appBar: '#1B1935',
    pageTitles: '#586276',
    pharmacy: {
      selected: '#D8EFFF',
    },
    darkBg: {
      text: '#000000',
      white: '#333333'
    }
  },
  fonts: {
    ...Preset.fonts,
    body: 'Open Sans, sans-serif',
    heading: 'Nunito, sans-serif',
  },

  icons,
};
