import Storage from '../storage';
import config from '../../config';
import createClient from '../createClient';
import auth from './auth';
import requisitions from './requisitions';
import requisitionsPdf from './requisitionsPdf';
import tasks from './tasks';
import comments from './comments';
import messages from './messages';
import reports from './reports';
import orders from './orders';
import organizations from './organizations';
import pharmacies from './pharmacies';
import recomendations from './recomendations';
import prescriptions from './prescriptions';
import woocomerceUsers from './woocomerceUsers';
import wooUsers from './wooUsers';
import wooProducts from './wooProducts';
import wooCategories from './wooCategories';
import wooOrders from './wooOrders';
import fedex from './fedex';
import downloadFile from './downloadFile';
import activityLogs from './activityLogs';
import newUsers from './newUsers';
import users from './users';
import roles from './roles';
import configurations from './configurations';

import recomendationComments from './recomendationComments';
import providers from './providers';

const client = createClient({ config, Storage });

const api = {
  auth: auth(client),
  requisitions: requisitions(client),
  requisitionsPdf: requisitionsPdf(client),
  tasks: tasks(client),
  comments: comments(client),
  messages: messages(client),
  reports: reports(client),
  orders: orders(client),
  organizations: organizations(client),
  pharmacies: pharmacies(client),
  recomendations: recomendations(client),
  recomendationComments: recomendationComments(client),
  providers: providers(client),
  prescriptions: prescriptions(client),
  woocomerceUsers: woocomerceUsers(client),
  fedex: fedex(client),
  wooUsers: wooUsers(client),
  wooProducts: wooProducts(client),
  wooCategories: wooCategories(client),
  wooOrders: wooOrders(client),
  downloadFile: downloadFile(client),
  activityLogs: activityLogs(client),
  newUsers: newUsers(client),
  users: users(client),
  roles: roles(client),
  configurations: configurations(client),
};

export default api;
