const hostname = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export default {
  hostname,
  api: {
    baseURL: hostname,
    timeout: 40000,
  },
  portal: {
    iddleTime: 15,
    countdowntimer: 30,
  },
};
