export default (client) => ({
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/users',
      params: { ...filterParams },
    });
  },
  get(id) {
    return client.request({
      method: 'get',
      url: `/users/${id}`,
    });
  },
  post(payload) {
    return client.request({
      method: 'post',
      url: '/users',
      data: payload,
    });
  },
  setPassword(payload) {
    return client.request({
      method: 'post',
      url: '/credentials',
      data: payload,
    });
  },
});
