import React, { useState, useEffect } from 'react';
import PropTypes, { arrayOf, number, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Nav from '@bit/medicalwebexperts.mwe-ui.nav';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import Divider from '@bit/medicalwebexperts.mwe-ui.divider';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Chip from '@bit/medicalwebexperts.mwe-ui.chip';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import SidebarMWE from '@bit/medicalwebexperts.mwe-ui.sidebar';
import logo from '../../assets/logo-microgen-black.png';
import items from './menuItems';
import withAuth from '../../context/withAuth';
import useIdleLogout from '../hooks/useIdleLogout';
import IdleLogoutModal from '../Common/IdleLogoutModal';
import Config from '../../config/index';
import events from '../../utils/events';

const NavItem = styled(Nav.Item)(
  { label: 'navItem' },
  css({
    padding: '5px 0 5px 3px',
  }),
  (props) =>
    props.selected &&
    css({
      borderRight: '5px solid',
      borderColor: 'sidebar.border',
      backgroundColor: 'sidebar.bg',
    }),
);

const NavIconContainer = styled(Container)(
  { label: 'NavIconContainer' },
  css({
    padding: 0,
    margin: '0 8px 0 11px',
    color: 'gray.400',
    display: 'inline-block',
  }),
  (props) =>
    props.selected &&
    css({
      color: 'primary.main',
    }),
);

const NumberChip = styled(Chip)(
  { label: 'Chip' },
  css({
    height: '12px',
    minWidth: '12px',
    span: {
      height: '11px',
      minWidth: '11px',
      width: '11px',
      fontSize: '8px',
      padding: 0,
    },
  }),
);

const propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sidebar: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.shape({
    name: string,
    unreadMsg: number,
    unreadTasks: number,
    roles: arrayOf(string),
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

const Sidebar = ({ location, auth, sidebar, user, setUser }) => {
  const { pathname } = location;
  const [paint, setPaint] = useState(false);

  const handleLogout = () => {
    auth.logout();
  };

  const [remainingSecs, isTimingOut] = useIdleLogout({
    countdowntimer: Config.portal.countdowntimer,
    idleTimer: Config.portal.iddleTime,
    handleLogout,
  });

  const handleChange = () => {
    setUser(auth.getUser());
    setPaint((prev) => !prev);
  };

  useEffect(() => {
    events.addListener('repaintSidebar', handleChange);
    // eslint-disable-next-line
  }, []);

  const getItems = (role, Items) => {
    let ret = [];
    Items.forEach((item) => {
      if (item.role.indexOf(role) !== -1) {
        ret = item.menuItems;
      }
    });
    return ret;
  };
  const menu = getItems(user.roles[0], items);
  return (
    <SidebarMWE
      state={sidebar}
      ariaLabel="menu"
      minWidth={{ _: '300px', lg: '230px' }}
      width={{ _: '300px', lg: '230px' }}
      position="fixed"
      boxShadow="none"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      height="auto"
    >
      <Image
        src={logo}
        width="200px"
        alt="Microgen Dx"
        margin="20px auto"
        display="block"
      />
      <Flex
        width="100%"
        background="rgba(0, 121, 254, 0.06)"
        alignItems="flex-start"
        mb="3"
        p="3"
      >
        <Flex mr="16px">
          <Box
            width="24px"
            height="24px"
            borderRadius="12px"
            bg="rgba(0, 121, 254, 0.46)"
            fontSize="22px"
            color="rgba(0,121,254,0.98)"
            textAlign="center"
            lineHeight="1"
          >
            <Icon
              name="person"
              width="22px"
              height="22px"
              background="primary.dark"
            />
          </Box>
        </Flex>
        <Flex flexDirection="column" width={{ _: '30px', lg: '20px' }}>
          <Typography
            as="div"
            style={{
              color: 'primary.dark',
              width: '150px',
              fontSize: '15px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {user.name}
          </Typography>

          <Link
            href="/profile"
            fontSize="12px"
            color="sidebar.profile"
            fontFamily="Nunito Sans"
            fontWeight="700"
          >
            Profile
          </Link>
        </Flex>
      </Flex>
      <Nav variant="vertical" flex="1" fontSize="16px" key={paint}>
        {menu.map((item) => (
          <NavItem key={item.path} selected={pathname.includes(item.path)}>
            <Nav.Link fontSize="sm" href={item.path} color="gray.700">
              <NavIconContainer selected={pathname.includes(item.path)}>
                {item.chip && user[item.chip] > 0 ? (
                  <NumberChip content={user[item.chip]} pulse height="12px">
                    {item.icon}
                  </NumberChip>
                ) : (
                  item.icon
                )}
              </NavIconContainer>{' '}
              {item.text}
            </Nav.Link>
          </NavItem>
        ))}
      </Nav>
      <Box
        padding="10px 0"
        width="100%"
        bg="white"
        onClick={auth.logout}
        style={{ cursor: 'pointer' }}
      >
        <Divider />
        <Typography
          marginLeft="20px"
          fontSize="14px"
          color="gray.700"
          fontFamily="Open Sans, sans-serif"
        >
          <Icon
            name="exit"
            color="sidebar.logout"
            marginRight="9px"
            marginLeft="3px"
            size="18px"
          />
          Log Out
        </Typography>
      </Box>
      <IdleLogoutModal status={isTimingOut} remainingSecs={remainingSecs} />
    </SidebarMWE>
  );
};

Sidebar.propTypes = propTypes;
export default withRouter(withAuth(Sidebar));
