import React from 'react';
import { node, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';

import LoggedInLayout from './LoggedInLayout';
import LoggedOutLayout from './LoggedOutLayout';

const propTypes = {
  children: node.isRequired,
  isLoggedIn: bool.isRequired,
};

const Layout = ({ children, isLoggedIn }) => {
  const Component = isLoggedIn ? LoggedInLayout : LoggedOutLayout;

  return <Component>{children}</Component>;
};

Layout.propTypes = propTypes;

export default withRouter(Layout);
