import React from 'react';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';

export default [
  {
    role: ['sales'],
    menuItems: [
      {
        text: 'Task Queue',
        path: '/tasking',
        icon: <Icon name="checkBox" size="18px" />,
        chip: 'unreadTasks',
      },
      {
        text: 'Messages',
        path: '/messages',
        icon: <Icon name="mailOutline" size="18px" />,
        chip: 'unreadMsg',
      },
    ],
  },
  {
    role: ['admin'],
    menuItems: [
      {
        text: 'Task Queue',
        path: '/tasking',
        icon: <Icon name="checkBox" size="18px" />,
        chip: 'unreadTasks',
      },
      {
        text: 'Messages',
        path: '/messages',
        icon: <Icon name="mailOutline" size="18px" />,
        chip: 'unreadMsg',
      },
      // {
      //   text: 'Link Woocomece Users',
      //   path: '/link-woocomerce-users',
      //   icon: <Icon name="shooping" size="18px" />,
      //   chip: false,
      // },
      {
        text: 'Assign roles',
        path: '/link-lims-users',
        icon: <Icon name="person" size="18px" />,
        chip: false,
      },
      {
        text: 'Configurations',
        path: '/configurations/banner',
        icon: <Icon name="settings" size="18px" />,
        chip: false,
      },
      {
        text: 'Activity Logs',
        path: '/activity-logs',
        icon: <Icon name="history" size="18px" />,
        chip: false,
      },
    ],
  },
  {
    role: ['medical'],
    menuItems: [
      {
        text: 'Requisitions and Results',
        path: '/requisitions',
        icon: <Icon name="description" size="18px" />,
      },
      {
        text: 'Education',
        path: '/education',
        icon: <Icon name="school" size="18px" />,
        chip: false,
      },
      {
        text: 'Support',
        path: '/support',
        icon: <Icon name="headset" size="18px" />,
        chip: false,
      },
      {
        text: 'Messages',
        path: '/messages',
        icon: <Icon name="mailOutline" size="18px" />,
        chip: 'unreadMsg',
      },
    ],
  },
  {
    role: ['pharmacy'],
    menuItems: [
      {
        text: 'Recommendations',
        path: '/recommendations',
        icon: <Icon name="localHospital" size="18px" />,
        chip: false,
      },
      {
        text: 'Support',
        path: '/support',
        icon: <Icon name="headset" size="18px" />,
        chip: false,
      },
      {
        text: 'Messages',
        path: '/messages',
        icon: <Icon name="mailOutline" size="18px" />,
        chip: 'unreadMsg',
      },
      {
        text: 'Education',
        path: '/education',
        icon: <Icon name="school" size="18px" />,
        chip: false,
      },
    ],
  },
];
