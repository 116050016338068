import css from '@styled-system/css';
import styled from '@emotion/styled';
import Modal from '@bit/medicalwebexperts.mwe-ui.modal';

const StyledModal = styled(Modal)(
  { label: 'CustomModal' },
  css({
    border: 'none',
    outline: 'none',
    button: {
      '&.noOutline': {
        outline: 'none',
      },
      outlineColor: 'primary.main',
    },
  }),
);

export default StyledModal;
