export default (client) => ({
  status() {
    return client.request({
      method: 'get',
      url: '/status/get',
    });
  },
  sampleTypes() {
    return client.request({
      method: 'get',
      url: '/sampleTypes/get',
    });
  },
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/requisitions',
      params: { ...filterParams },
    });
  },
  get(id) {
    return client.request({
      method: 'get',
      url: `/requisitions/${id}`,
    });
  },
  post(payload) {
    return client.request({
      method: 'post',
      url: '/requisitions',
      data: payload,
    });
  },
});
