import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';

import logoImage from '../../assets/logo-microgen-black.png';
import robotImage from '../../assets/robot.jpg';

const propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const Maintenance = ({ from, to }) => (
  <Flex flexDirection="column" alignItems="center" px={4}>
    <Image src={logoImage} width={200} alt="Microgen Dx" mt={4} mb={8} />
    <Image src={robotImage} width={350} alt="" mb={8} />
    <Typography maxWidth={600} mb={8}>
      <b>MGDX Provider Portal</b> is undergoin scheduled maintenance to enhance
      your experience. We aplogize for any inconvenience this may cause and
      appreciate your patient.
    </Typography>
    <Typography>
      <b>Expected duration:</b> {moment(from).format('hh:mm A')} -{' '}
      {moment(to).format('hh:mm A')}
    </Typography>
  </Flex>
);

Maintenance.propTypes = propTypes;

export default Maintenance;
