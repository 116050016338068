export default (client) => ({
  get(filterParams) {
    return client.request({
      method: 'get',
      url: '/pharmacies/get',
      params: { ...filterParams },
    });
  },
  post(payload) {
    return client.request({
      method: 'post',
      url: '/pharmacies',
      data: payload,
    });
  },
});
