export default (client) => ({
  get(id) {
    return client.request({
      method: 'get',
      url: `/tasks/get/${id}`,
    });
  },
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/tasks',
      params: { ...filterParams },
    });
  },
  create(payload) {
    return client.request({
      method: 'post',
      url: '/tasks',
      data: payload,
    });
  },
  patch(id, payload) {
    return client.request({
      method: 'put',
      url: `/tasks/${id}`,
      data: payload,
    });
  },
  delete(id) {
    return client.request({
      method: 'delete',
      url: `/tasks/${id}`,
    });
  },
});
