export default (client) => ({
  create(payload) {
    const formData = new FormData();
    Object.keys(payload).forEach((field) =>
      formData.append(field, payload[field]),
    );

    return client.request({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: '/prescriptions',
      data: formData,
    });
  },
});
