import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Redirect,
} from 'react-router-dom';
import ProgressBar from '@bit/medicalwebexperts.mwe-ui.progress-bar';
import Layout from './components/Layout/Layout';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import withAuth from './context/withAuth';

const LoginPage = lazy(() => import('./pages/LoginPage'));

const SignupPage = lazy(() => import('./pages/SignupPage'));

const SetPasswordPage = lazy(() => import('./pages/SetPasswordPage'));

const ProfilePage = lazy(() => import('./pages/ProfilePage'));

const SupportPage = lazy(() => import('./pages/SupportPage'));

const MessagesPage = lazy(() => import('./pages/MessagesPage'));

const PharmaciesPage = lazy(() => import('./pages/PharmaciesPage'));

const RecomendationPage = lazy(() => import('./pages/RecomendationPage'));

const EducationPage = lazy(() => import('./pages/Education/EducationPage'));

const InformationBanner = lazy(() =>
  import('./pages/Configurations/InformationBanner'),
);

const EducationFaqsPage = lazy(() =>
  import('./pages/Education/EducationFaqsPage'),
);

const EducationGuidesPage = lazy(() =>
  import('./pages/Education/EducationGuidesPage'),
);

const TaskingQueuePage = lazy(() => import('./pages/TaskingQueuePage'));

const TaskingQueueDertailsPage = lazy(() =>
  import('./pages/TaskingQueueDertailsPage'),
);

const RequisitionsSearchPage = lazy(() =>
  import('./pages/requisitionsAndResults/RequisitionsSearchPage'),
);

const RequisitionsMultiplePage = lazy(() =>
  import('./pages/requisitionsAndResults/RequisitionsMultiplePage'),
);

const RequisitionFormPage = lazy(() =>
  import('./pages/requisitionsAndResults/RequisitionFormPage'),
);

const FormQuestionsPage = lazy(() => import('./pages/FormQuestionsPage'));

const OrderTestPage = lazy(() => import('./pages/OrderTestPage'));

const OrderProductDetailsPage = lazy(() =>
  import('./pages/OrderProductDetailsPage'),
);

const PickupsPage = lazy(() => import('./pages/PickupsPage'));

const NoMatchPage = lazy(() => import('./pages/404'));

const NewFormPage = lazy(() => import('./pages/NewFormPage'));

const LinkWooUsers = lazy(() => import('./pages/LinkWooUsers'));

const UserRoles = lazy(() => import('./pages/UserRoles'));

const ActivityLogsPage = lazy(() => import('./pages/ActivityLogsPage'));

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <ProgressBar
        classes={{
          root: { position: 'absolute', top: 0, left: 0 },
        }}
        size="sm"
      />
    }
  />
);

const propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
};

function App(props) {
  const { isLoggedIn } = props;

  return (
    <Router>
      <Layout isLoggedIn={isLoggedIn()}>
        <Loader>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route
              exact
              path="/account/set-password/:token"
              component={SetPasswordPage}
            />
            <PrivateRoute
              path="/configurations/banner"
              component={InformationBanner}
              roles={['admin']}
            />
            <PrivateRoute
              path="/configurations/banner"
              component={FormQuestionsPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/requisitions/multiple"
              component={RequisitionsMultiplePage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/requisitions/form/:requisitionID"
              component={RequisitionFormPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/requisitions/form"
              component={RequisitionFormPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/requisitions/newform"
              component={NewFormPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/requisitions"
              component={RequisitionsSearchPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/support"
              component={SupportPage}
              roles={['medical', 'pharmacy']}
            />
            <PrivateRoute
              path="/messages"
              component={MessagesPage}
              roles={['admin', 'medical', 'sales', 'pharmacy']}
            />
            <PrivateRoute
              path="/pharmacies"
              component={PharmaciesPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/recommendations"
              component={RecomendationPage}
              roles={['pharmacy']}
            />
            <PrivateRoute
              path="/education/faqs"
              component={EducationFaqsPage}
              roles={['medical', 'pharmacy']}
            />
            <PrivateRoute
              path="/education/guides"
              component={EducationGuidesPage}
              roles={['medical', 'pharmacy']}
            />
            <PrivateRoute
              path="/education"
              component={EducationPage}
              roles={['medical', 'pharmacy']}
            />
            <PrivateRoute
              path="/tasking/edit-task"
              component={TaskingQueueDertailsPage}
              roles={['admin', 'sales']}
            />
            <PrivateRoute
              path="/tasking"
              component={TaskingQueuePage}
              roles={['admin', 'sales']}
            />
            <PrivateRoute
              path="/profile"
              component={ProfilePage}
              roles={['admin', 'medical', 'sales', 'pharmacy']}
            />
            <PrivateRoute
              path="/orders"
              component={OrderTestPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/orderProductDetails/:productID/:name"
              component={OrderProductDetailsPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/pickups"
              component={PickupsPage}
              roles={['medical']}
            />
            <PrivateRoute
              path="/link-woocomerce-users"
              component={LinkWooUsers}
              roles={['admin']}
            />
            <PrivateRoute
              path="/link-lims-users"
              component={UserRoles}
              roles={['admin']}
            />
            <PrivateRoute
              path="/activity-logs"
              component={ActivityLogsPage}
              roles={['admin']}
            />
            <Route component={NoMatchPage} />
          </Switch>
        </Loader>
      </Layout>
    </Router>
  );
}
App.propTypes = propTypes;
export default withAuth(App);
