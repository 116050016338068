export default (client) => ({
  get(id) {
    return client.request({
      method: 'get',
      url: `/orders/${id}`,
    });
  },
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/orders',
      params: { ...filterParams },
    });
  },

  post(payload) {
    return client.request({
      method: 'post',
      url: '/orders',
      data: payload,
    });
  },
});
