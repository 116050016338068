export default (client) => ({
  get(id) {
    return client.request({
      method: 'get',
      url: `/messages/get/${id}`,
    });
  },
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/messages',
      params: { ...filterParams },
    });
  },
  create(payload) {
    const formData = new FormData();
    Object.keys(payload).forEach((field) =>
      formData.append(field, payload[field]),
    );

    return client.request({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: '/messages',
      data: formData,
    });
  },
  patch(id, payload) {
    return client.request({
      method: 'patch',
      url: `/messages/${id}`,
      data: payload,
    });
  },
});
