import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';

const propTypes = {
  children: node.isRequired,
};

const Root = styled('div')(css({ display: 'flex' }));

const Wrapper = styled('main')(
  css({
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'logoutBg',
    input: {
      outlineColor: 'primary.main',
    },
    button: {
      outlineColor: 'primary.main',
    },
  }),
);

const LoggedOutLayout = ({ children }) => (
  <Root>
    <Wrapper>{children}</Wrapper>
  </Root>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
