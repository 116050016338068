export default (client) => ({
  find(filterParams) {
    return client.request({
      method: 'get',
      url: `/download-file`,
      responseType: 'blob',
      params: { ...filterParams },
    });
  },
});
