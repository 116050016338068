import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleLogout = ({ handleLogout, countdowntimer, idleTimer }) => {
  const [timeouts, setTimeouts] = useState([]);
  const [isTimingOut, setIsTimingOut] = useState(false);
  const [remainingCalls, setRemainingCalls] = useState();

  const handleRemainingMinute = (calls) => {
    let remainingCallsControl = calls;

    if (!calls && calls !== 0) {
      remainingCallsControl = countdowntimer;
      setRemainingCalls(countdowntimer);
    } else {
      setRemainingCalls(remainingCallsControl);
    }

    if (isTimingOut && remainingCallsControl === 0) {
      handleLogout();
    } else if (isTimingOut && remainingCallsControl > 0) {
      const timer = setTimeout(
        () => handleRemainingMinute(remainingCallsControl - 1),
        1000,
      );
      setTimeouts([...timeouts, timer]);
    } else if (!isTimingOut) {
      timeouts.forEach((t) => clearTimeout(t));
    }
  };

  const handleActivity = () => setIsTimingOut(!isTimingOut);

  useIdleTimer({
    timeout: 1000 * 60 * idleTimer,
    onIdle: handleActivity,
    onActive: handleActivity,
  });

  // eslint-disable-next-line
  useEffect(handleRemainingMinute, [isTimingOut]);

  return [remainingCalls, isTimingOut];
};

export default useIdleLogout;
