import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import menuitems from '../Sidebar/menuItems';
import withAuth from '../../context/withAuth';

const propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  component: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  customProps: PropTypes.oneOfType([PropTypes.object]),
};

const defaultProps = {
  customProps: {},
  roles: [],
};

const getItems = (role, Items) => {
  let ret = [];
  Items.forEach((item) => {
    if (item.role.indexOf(role) !== -1) {
      ret = item.menuItems;
    }
  });
  return ret;
};

const PrivateRoute = ({
  component: Component,
  auth,
  location,
  roles,
  customProps,
  ...rest
}) => {
  if (!auth.isLoggedIn() && location.pathname !== '/') {
    return <Redirect to="/" />;
  }

  // ROLE CHECKS
  if (auth.isLoggedIn() && roles.length > 0) {
    const userRoles = auth.getUser().roles;
    let found = false;

    roles.forEach((role) => {
      if (userRoles.indexOf(role.toLowerCase()) !== -1) {
        found = true;
      }
    });
    if (!found) {
      const menu = getItems(userRoles[0], menuitems);
      return <Redirect to={menu[0].path} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} customProps={customProps} />}
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;
export default withRouter(withAuth(PrivateRoute));
