import React from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';
import Storage from '../services/storage';

import events from '../utils/events';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const DEFAULT_STATE = {
  userLink: null,
  profileLink: null,
};

export const AuthContext = React.createContext(DEFAULT_STATE);

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = DEFAULT_STATE;

    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.getUserLink = this.getUserLink.bind(this);
    this.getProfileLink = this.getProfileLink.bind(this);
    this.handleAutologin = this.handleAutologin.bind(this);

    events.addListener('logout', this.handleLogout);
  }

  async handleAutologin() {
    const token = this.getToken();

    if (token) {
      const { data } = await api.auth.autologin(token);
      this.setState({
        userLink: data.userLink.url,
        profileLink: data.profileLink.url,
      });
    }
  }

  getToken = () => Storage.getItem('token') || null;

  getUserLink() {
    const { userLink } = this.state;
    return userLink;
  }

  getProfileLink() {
    const { profileLink } = this.state;
    return profileLink;
  }

  handleLogout = () => {
    Storage.clear();
    this.forceUpdate();
  };

  handleLogin = async (username, password) => {
    const { data } = await api.auth.login({ username, password });
    Storage.setItem('token', data.accessToken);
    Storage.setItem('backToken', data.access_token);

    const response = await api.auth.profile();
    const user = response.data;
    Storage.setObject('user', user);
    this.forceUpdate();
  };

  setUser = (user) => Storage.setObject('user', user);

  getUser = () => Storage.getObject('user') || null;

  getBakToken = () => Storage.getItem('backToken') || null;

  isLoggedIn() {
    return this.getToken() !== null && this.getUser() !== null;
  }

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          getUser: this.getUser,
          setUser: this.setUser,
          login: this.handleLogin,
          getToken: this.getToken,
          logout: this.handleLogout,
          isLoggedIn: this.isLoggedIn,
          getUserLink: this.getUserLink,
          autologin: this.handleAutologin,
          getProfileLink: this.getProfileLink,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = propTypes;
export default AuthProvider;
