import React from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from './AuthProvider';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthConsumer = ({ children }) => (
  <AuthContext.Consumer>
    {({ login, logout, getUser, getToken, isLoggedIn }) =>
      React.Children.map(children, (child) =>
        React.cloneElement(child, {
          login,
          logout,
          getUser,
          getToken,
          isLoggedIn,
        }),
      )
    }
  </AuthContext.Consumer>
);

AuthConsumer.propTypes = propTypes;
export default AuthConsumer;
