import { useMedia } from 'react-use';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { node, shape } from 'prop-types';
import BaseSidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Chip from '@bit/medicalwebexperts.mwe-ui.chip';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import { useTheme } from '@bit/medicalwebexperts.mwe-ui.utils.styled';

import api from '../../services/api';
import Sidebar from '../Sidebar/Sidebar';
import withAuth from '../../context/withAuth';
import logoWhite from '../../assets/logo-microgen-white.png';

const propTypes = {
  children: node.isRequired,
  auth: shape({}).isRequired,
};

const Root = styled('div')(css({ display: 'flex' }), ({ isMobile }) =>
  css({
    flexDirection: isMobile ? 'column' : 'row',
  }),
);

const Wrapper = styled('main')(
  css({
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: 'bgcolor',
    paddingLeft: [0, 0, 0, '230px', '230px'],
    input: {
      outlineColor: 'primary.main',
      ':active': {
        boxShadow: '0 0 0 3px rgba(0, 123, 255, .5)',
      },
    },
    select: {
      outlineColor: 'primary.main',
    },
    button: {
      outlineColor: 'primary.main',
    },
  }),
);

const Content = styled('div')(
  css({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: ['3', '3', '3', '3', '12'],
  }),
  (props) =>
    props.showAppBar &&
    css({
      marginTop: '64px',
    }),
);

const Toolbar = styled.div(
  css({
    alignItems: 'center',
    display: 'flex',
    minHeight: 16,
    px: 4,
  }),
);

const PendingChip = styled(Chip)(
  { label: 'Chip' },
  css({
    // height: '12px',
    // minWidth: '12px',
    span: {
      height: '11px',
      minWidth: '11px',
      width: '11px',
      fontSize: '8px',
      padding: 0,
    },
  }),
);

const LoggedInLayout = ({ auth, children }) => {
  const [user, setUser] = useState(() => auth.getUser());
  const sidebar = useSidebarState({ animated: true });
  const useUp = (breakpoint) => {
    const { breakpoints } = useTheme();
    const query = `(min-width: ${breakpoints[breakpoint]})`;
    return useMedia(query);
  };
  const isMobile = !useUp('md');
  const showAppBar = !useUp('lg');

  const loadProfile = useCallback(async () => {
    try {
      const { data } = await api.auth.profile();
      auth.setUser(data);
      setUser(data);
    } catch {
      //
    }
  }, [auth]);

  useEffect(() => {
    loadProfile();
    const load = setInterval(loadProfile, 60000);

    return () => clearInterval(load);
  }, [loadProfile]);

  return (
    <Root isMobile={isMobile}>
      <Sidebar sidebar={sidebar} user={user} setUser={setUser} />
      <Wrapper>
        {showAppBar && (
          <AppBar bg="appBar">
            <Toolbar>
              <BaseSidebar.Disclosure {...sidebar}>
                {(props) => (
                  <>
                    {user.unreadMsg > 0 || user.unreadTasks > 0 ? (
                      <PendingChip pulse overlap="circle" position="topLeft">
                        <IconButton
                          size="lg"
                          color="white"
                          {...props}
                          onclick={() => {
                            sidebar.toggle();
                          }}
                        >
                          <Icon name="menu" size="8" />
                        </IconButton>
                      </PendingChip>
                    ) : (
                      <IconButton
                        size="lg"
                        color="white"
                        {...props}
                        onclick={() => {
                          sidebar.toggle();
                        }}
                      >
                        <Icon name="menu" size="8" />
                      </IconButton>
                    )}
                  </>
                )}
              </BaseSidebar.Disclosure>
              <Box flexGrow="1">
                <Image
                  src={logoWhite}
                  width="125px"
                  alt="Microgen Dx"
                  margin="10px auto"
                  display="block"
                />
              </Box>
            </Toolbar>
          </AppBar>
        )}
        <Content showAppBar={showAppBar}>{children}</Content>
      </Wrapper>
    </Root>
  );
};

LoggedInLayout.propTypes = propTypes;

export default withAuth(LoggedInLayout);
