import {
  MdArrowBack,
  MdArrowDropDown,
  MdAssignment,
  MdAssignmentTurnedIn,
  MdCancel,
  MdClass,
  MdClose,
  MdCloudDownload,
  MdCloudUpload,
  MdCreditCard,
  MdErrorOutline,
  MdEvent,
  MdExitToApp,
  MdFindInPage,
  MdHeadsetMic,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLock,
  MdPermContactCalendar,
  MdPerson,
  MdQuestionAnswer,
  MdRecordVoiceOver,
  MdSearch,
  MdSettings,
  MdTune,
  MdVisibility,
  MdVisibilityOff,
  MdChevronLeft,
  MdChevronRight,
  MdDescription,
  MdMenu,
  MdMailOutline,
  MdLocalHospital,
  MdSchool,
  MdCheckBox,
  MdLocalShipping,
  MdMessage,
  MdAccountBox,
  MdArrowForward,
  MdLiveHelp,
  MdNotificationsActive,
  MdMail,
  MdWarning,
  MdAttachFile,
  MdHistory,
  MdShoppingCart,
  MdEmail,
  MdInfoOutline,
} from 'react-icons/md';
import {
  HiShoppingCart,
  HiOutlineMinusCircle,
  HiOutlinePlusCircle,
} from 'react-icons/hi';
import {
  FaPrescription,
  FaUserNurse,
  FaFilePrescription,
  FaRegFile,
} from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineUser } from 'react-icons/ai';

const icons = {
  chevronLeft: MdChevronLeft,
  chevronRight: MdChevronRight,
  arrowDropDown: MdArrowDropDown,
  arrowRight: MdKeyboardArrowRight,
  description: MdDescription,
  menu: MdMenu,
  close: MdClose,
  search: MdSearch,
  cancel: MdCancel,
  assignment: MdAssignment,
  mailOutline: MdMailOutline,
  localHospital: MdLocalHospital,
  school: MdSchool,
  checkBox: MdCheckBox,
  localShipping: MdLocalShipping,
  message: MdMessage,
  accountBox: MdAccountBox,
  arrowForward: MdArrowForward,
  liveHelp: MdLiveHelp,
  shoppingCart: HiShoppingCart,
  cloudDownload: MdCloudDownload,
  visibility: MdVisibility,
  visibilityOff: MdVisibilityOff,
  lock: MdLock,
  person: MdPerson,
  tune: MdTune,
  assignmentTurnedIn: MdAssignmentTurnedIn,
  notificationsActive: MdNotificationsActive,
  mail: MdMail,
  warning: MdWarning,
  headset: MdHeadsetMic,
  arrowBack: MdArrowBack,
  arrowDown: MdKeyboardArrowDown,
  arrowLeft: MdKeyboardArrowLeft,
  class: MdClass,
  cloudUpload: MdCloudUpload,
  creditCard: MdCreditCard,
  error: MdErrorOutline,
  event: MdEvent,
  exit: MdExitToApp,
  findInPage: MdFindInPage,
  personCalendar: MdPermContactCalendar,
  questionAnswer: MdQuestionAnswer,
  recordVoiceOver: MdRecordVoiceOver,
  settings: MdSettings,
  attachFile: MdAttachFile,
  prescription: FaPrescription,
  doctor: FaUserNurse,
  plus: HiOutlinePlusCircle,
  minus: HiOutlineMinusCircle,
  filePrescription: FaFilePrescription,
  file: FaRegFile,
  history: MdHistory,
  shooping: MdShoppingCart,
  email: MdEmail,
  info: MdInfoOutline,
  team: AiOutlineTeam,
  teamMember: AiOutlineUser,
};

export default icons;
