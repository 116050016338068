export default (client) => ({
  login({ username, password }) {
    return client.request({
      method: 'post',
      url: '/authentication',
      data: { username, password, strategy: 'local' },
    });
  },
  profile() {
    return client.request({
      method: 'get',
      url: '/users/get',
    });
  },
  updateUser(data) {
    return client.request({
      method: 'put',
      url: '/users/1',
      data,
    });
  },
});
