import React from 'react';
import ReactDOM from 'react-dom';
import { Global, css } from '@emotion/react';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import SnackbarProvider from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import AuthProvider from './context/AuthProvider';
import AuthConsumer from './context/AuthConsumer';
import MaintenanceProvider from './context/MaintenanceProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          body {
            margin: 0;
          }
        `}
      />{' '}
      <SnackbarProvider>
        <MaintenanceProvider>
          <AuthProvider>
            <AuthConsumer>
              <App />
            </AuthConsumer>
          </AuthProvider>
        </MaintenanceProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
