export default (client) => ({
  get(id) {
    return client.request({
      method: 'get',
      url: `/recomendation-comments/get/${id}`,
    });
  },
  find(filterParams) {
    return client.request({
      method: 'get',
      url: '/recomendation-comments',
      params: { ...filterParams },
    });
  },
  create(payload) {
    return client.request({
      method: 'post',
      url: '/recomendation-comments',
      data: payload,
    });
  },
  patch(id, payload) {
    return client.request({
      method: 'put',
      url: `/recomendation-comments/${id}`,
      data: payload,
    });
  },
});
